export type IntercomWindow = {
  Intercom?: any
  intercomSettings?: IntercomSettings
  attachEvent?: any
} & Window &
  typeof globalThis

export type IntercomSettings = {
  app_id: string
  alignment?: 'left' | 'right'
  horizontal_padding?: number
  vertical_padding?: number
}

export const intercomSettings: IntercomSettings = {
  app_id: process.env.REACT_APP_INTERCOM_ID,
}

function createScript() {
  var s = document.createElement('script')
  s.type = 'text/javascript'
  s.async = true
  s.src = 'https://widget.intercom.io/widget/' + process.env.REACT_APP_INTERCOM_ID
  var x = document.getElementsByTagName('head')[0]
  x.appendChild(s)
}

function init() {
  const intercomWindow = window as IntercomWindow
  const intercom = intercomWindow.Intercom
  intercomWindow.intercomSettings = intercomSettings

  if (typeof intercom === 'function') {
    intercom('reattach_activator')
    intercom('update', intercomWindow.intercomSettings)
  } else {
    // eslint-disable-next-line func-style
    const i: any = function () {
      i.c(arguments)
    }

    i.q = []
    i.c = function (args: any) {
      i.q.push(args)
    }

    intercomWindow.Intercom = i

    if (document.readyState === 'complete') {
      createScript()
    } else if (intercomWindow.attachEvent) {
      intercomWindow.attachEvent('onload', createScript)
    } else {
      intercomWindow.addEventListener('load', createScript, false)
    }
  }
}

export default { init }
