import { useCallback, useMemo } from 'react'

import {
  LocationSelect as BaseLocationSelect,
  type LocationSelectProps as BaseLocationSelectProps,
  type FetchLocationOptions,
  type Location,
} from '@loadsmart/react-location-select'

import { type Option } from '../../../domain/Option'
import { API_URL, COMPASS_API_KEY, GOOGLE_MAPS_KEY } from '../../../infra/environment'
import { toArray } from '../../../utils/array'
import { isFunction } from '../../../utils/function'

export const GOOGLE_MAPS_CONFIG: FetchLocationOptions = {
  types: ['cities'],
  restrictions: { country: ['us', 'ca', 'mx'] },
}

type BaseProps<T> = Omit<
  BaseLocationSelectProps,
  'value' | 'onChange' | 'multiple' | 'googleMapsAPIKey'
> & {
  mapValueToOption: (value: T) => Option
  mapLocationToValue: (location: Location) => T
}

export type LocationSelectSingleProps<T> = BaseProps<T> & {
  multiple?: false
  value?: T
  onChange?: (value?: T) => void
}

type MultipleProps<T> = BaseProps<T> & {
  multiple: true
  value?: Array<T>
  onChange?: (value?: Array<T>) => void
}

export type LocationSelectProps<T> = LocationSelectSingleProps<T> | MultipleProps<T>

/**
 * Wrapper around the @loadsmart/react-location-select to make the life easier.
 */
export function LocationSelect<T>({
  config = GOOGLE_MAPS_CONFIG,
  multiple = false,
  value,
  onChange,
  mapValueToOption,
  mapLocationToValue,
  ...props
}: LocationSelectProps<T>) {
  const handleChange = useCallback(
    (event: any) => {
      if (!isFunction(onChange)) {
        return
      }

      const newValue: Array<Location> | Location | null = event?.target?.value
      const callback = onChange as (value?: Array<T> | T) => void
      const values = toArray(newValue).map(mapLocationToValue)

      if (values.length === 0) {
        callback(undefined)
      } else if (multiple) {
        callback(values)
      } else {
        callback(values[0])
      }
    },
    [mapLocationToValue, multiple, onChange]
  )

  const selected = useMemo(() => {
    const options = toArray(value).map(mapValueToOption)

    if (options.length === 0) {
      return undefined
    } else if (multiple) {
      return options
    } else {
      return options[0]
    }
  }, [mapValueToOption, multiple, value])

  return (
    <BaseLocationSelect
      googleMapsAPIKey={GOOGLE_MAPS_KEY}
      compassConfig={{
        APIKey: COMPASS_API_KEY!,
        baseURL: API_URL,
      }}
      config={config}
      multiple={multiple}
      value={selected}
      onChange={handleChange}
      {...props}
    />
  )
}
