import { type Stop } from 'core/domain/Stop'
import { type TruckType } from 'core/domain/Truck'
import { type Weekday } from 'core/domain/Weekday'
import { parseNumber } from 'core/utils/number'

import { type LoadStop } from 'loads/domain/LoadStop'

import { type Channel } from './Channel'
import { type Place } from './Place'

export const DEFAULT_EQUIPMENT: TruckType = 'DRV'
export const DEFAULT_RADIUS = 150

export const PREFERRED_LANE_SETUP_LIMIT = 3

export type PreferredLane = {
  channel?: Channel
  destination?: Place
  equipmentTypes?: Array<TruckType>
  expiresAt?: string
  origin?: Place
  rate?: number
  startsAt?: string
  suggestedRate?: number
  uuid?: string
  weekdays?: Array<Weekday>
}

export function canAddMoreLanes(preferredLanesCount: number): boolean {
  return preferredLanesCount < PREFERRED_LANE_SETUP_LIMIT
}

export function createPreferredLane(
  stops: Array<Stop | LoadStop>,
  equipmentType?: string
): PreferredLane {
  const { 0: pickup, [stops.length - 1]: delivery } = stops

  return {
    origin: {
      description: `${pickup.city}, ${pickup.state}, ${pickup.country}`,
      latitude: parseNumber(pickup.latitude),
      longitude: parseNumber(pickup.longitude),
      radius: 50,
    },
    destination: {
      description: `${delivery.city}, ${delivery.state}, ${pickup.country}`,
      latitude: parseNumber(delivery.latitude),
      longitude: parseNumber(delivery.longitude),
      radius: 50,
    },
    equipmentTypes: equipmentType ? ([equipmentType] as Array<TruckType>) : undefined,
  }
}

/**
 * Returns the EquipmentTypes from a PreferredLane. Fallback to
 * `DEFAULT_EQUIPMENT` if the PreferredLane does not have any EquipmentTypes.
 */
export function getEquipmentTypes({ equipmentTypes }: PreferredLane): Array<TruckType> {
  if (Array.isArray(equipmentTypes) && equipmentTypes.length > 0) {
    return equipmentTypes
  }
  return [DEFAULT_EQUIPMENT]
}

/**
 * The same as `getPreferredLaneKey`, but without whitespaces, so it's suited to
 * be used as HTML5 id.
 */
export function getPreferredLaneId(preferredLane: PreferredLane): string {
  return getPreferredLaneKey(preferredLane).replaceAll(' ', '')
}

export function getPreferredLaneKey(preferredLane: PreferredLane): string {
  const origin = preferredLane.origin?.description ?? 'Anywhere'
  const destination = preferredLane.destination?.description ?? 'Anywhere'
  const equipmentType = getEquipmentTypes(preferredLane).join(',')

  return `${origin}/${destination}/${equipmentType}`
}

/**
 * Try to return the radius from the origin, and if not present, the radius from
 * the destination. If both values are not present, return the `DEFAULT_RADIUS`.
 */
export function getRadius({ origin, destination }: PreferredLane): number {
  return origin?.radius ?? destination?.radius ?? DEFAULT_RADIUS
}

export function hasPreferredLane(preferredLanes: Array<PreferredLane>, uuid: string): boolean {
  return preferredLanes.some(preferredLane => preferredLane.uuid === uuid)
}

export function removePreferredLane(
  preferredLanes: Array<PreferredLane>,
  uuid: string
): Array<PreferredLane> {
  return preferredLanes.filter(preferredLane => preferredLane.uuid !== uuid)
}

export function updatePreferredLane(
  preferredLanes: Array<PreferredLane>,
  updatedPreferredLane: PreferredLane
): Array<PreferredLane> {
  return preferredLanes.map(preferredLane => {
    if (preferredLane.uuid === updatedPreferredLane.uuid) {
      return updatedPreferredLane
    }
    return preferredLane
  })
}
