import { useCallback, useState } from 'react'

import { RequestStatus } from 'core/domain/Request'
import { type HttpClientConfig } from 'core/infra/http/HttpClient'

import analytics from 'legacy/utils/analytics'
import user from 'legacy/utils/user'

import { type Carrier } from '../../domain/Carrier'
import { getCarrier } from '../../use-cases/getCarrier'

type UseCarrierState = {
  carrier?: Carrier
  status: RequestStatus
}

export function useCarrier() {
  const [state, setState] = useState<UseCarrierState>({ status: RequestStatus.Initial })
  const isLoading = state.status === RequestStatus.Pending

  function setCarrier(carrier: Carrier) {
    setState({ ...state, carrier })
  }

  const fetchCarrier = useCallback(async (config?: HttpClientConfig) => {
    setState({ status: RequestStatus.Pending })
    try {
      const carrier = await getCarrier(config)

      analytics.setProperty({ entityType: carrier.entityType })
      user.set('mcNumber', `${carrier.mc}`)
      user.set('verified', `${carrier.contractingRequirementsPasses}`)
      user.set('entityType', carrier.entityType)

      setState({ status: RequestStatus.Done, carrier })
    } catch (error: any) {
      const isUnauthorized = [401, 403].includes(error.response?.status)

      if (isUnauthorized) {
        user.logout()
        window.location.reload()
      }

      setState({ status: RequestStatus.Error })
    }
  }, [])

  return {
    ...state,
    isLoading,
    fetchCarrier,
    setCarrier,
  }
}
