import axios from 'axios'
import queryString from 'query-string'

import { CLIENT } from 'legacy/constants/clients'
import http from 'legacy/utils/http'

export function getTokenV2(data, client = {}) {
  return axios.post('/api/v2/oauth/token', data, http.getPublicHeadersForm(client))
}

export async function getClientToken() {
  const { data } = await getTokenV2(
    queryString.stringify({ grant_type: 'client_credentials' }),
    CLIENT
  )
  return `${data.token_type} ${data.access_token}`
}

export async function getRequestConfig() {
  const requestConfig = http.getPublicHeadersV2()
  requestConfig.headers.Authorization = await getClientToken()
  return requestConfig
}
