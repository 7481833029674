import PropTypes from 'prop-types'
import { useCallback } from 'react'
import styled from 'styled-components/macro'

import { Select } from '@loadsmart/loadsmart-ui'
import { Layout } from '@loadsmart/miranda-react'

import { LocationSelect } from 'core/ui/components/location/LocationSelect'

import { screen } from 'legacy/styles/screen'

import { laneType } from '../propTypes'

const RADIUS_AVAILABLE_VALUES = [10, 20, 50, 100, 150, 200, 300]

const RADIUS_OPTIONS = RADIUS_AVAILABLE_VALUES.map(radiusValue => ({
  label: `${radiusValue} mi`,
  value: radiusValue,
}))

export const DEFAULT_RADIUS_VALUE = RADIUS_AVAILABLE_VALUES[4]

const CUSTOM_GOOGLE_MAPS_CONFIG = {
  types: ['regions'],
  restrictions: { country: ['us', 'ca', 'mx'] },
}

const StyledSelect = styled(Select)`
  width: 200px;

  ${screen.md} {
    width: 120px;
    min-width: 100px;
    margin-left: 6px;
  }
`
function mapLocationToOption(value) {
  return {
    label: value.address,
    value,
  }
}

function isLaneValid({ pickup, delivery }) {
  return Boolean(pickup || delivery)
}

export function SelectLane({ onChange, lane }) {
  const setLaneStopLocation = useCallback(
    (stop, location) => {
      const nextLane = { ...lane, [stop]: location }

      if (isLaneValid(nextLane)) {
        if (lane[stop]?.address !== nextLane[stop]?.address) onChange(nextLane)
      } else {
        onChange()
      }
    },
    [lane, onChange]
  )

  const setLaneRadius = useCallback(
    radius => {
      if (radius === lane.radius) return
      const nextLane = { ...lane, radius }
      onChange(nextLane)
    },
    [lane, onChange]
  )

  const handleRadiusChange = useCallback(
    option => {
      const value = option?.value
      if (value) return setLaneRadius(value)
      return setLaneRadius(DEFAULT_RADIUS_VALUE)
    },
    [setLaneRadius]
  )

  return (
    <Layout.Group>
      <LocationSelect
        name="pickup"
        placeholder="Pickup"
        mapLocationToValue={location => location}
        mapValueToOption={mapLocationToOption}
        onChange={location => setLaneStopLocation('pickup', location)}
        config={CUSTOM_GOOGLE_MAPS_CONFIG}
      />
      <LocationSelect
        name="delivery"
        placeholder="Delivery"
        mapLocationToValue={location => location}
        mapValueToOption={mapLocationToOption}
        onChange={location => setLaneStopLocation('delivery', location)}
        config={CUSTOM_GOOGLE_MAPS_CONFIG}
      />
      <StyledSelect
        hideClear
        placeholder="Radius"
        multiple={false}
        options={RADIUS_OPTIONS}
        onChange={({ target }) => handleRadiusChange(target.value)}
        value={RADIUS_OPTIONS.find(({ value }) => value === lane.radius)}
      />
    </Layout.Group>
  )
}

SelectLane.propTypes = {
  onChange: PropTypes.func.isRequired,
  lane: laneType.isRequired,
}

SelectLane.defaultProps = {
  lane: {
    pickup: null,
    delivery: null,
    radius: DEFAULT_RADIUS_VALUE,
  },
}
