import PropTypes from 'prop-types'
import { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'

import { Checkbox } from 'legacy/components/Checkbox/Checkbox'
import { DateInput } from 'legacy/components/DateInput/DateInput'
import { formatDate } from 'legacy/utils/date/formatDate'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  font-size: 14px;
`

const CheckboxWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  width: 60px;

  font-size: 13px;

  :first-child {
    margin-right: 5px;
  }
`

const StyledDateInput = styled(DateInput)`
  width: calc(100% - 65px);
`

function toDateQueryFormat(date) {
  return formatDate(date, "yyyy-MM-dd'T'00:00:00")
}
function stateDateFormatter(date) {
  return formatDate(date, 'yyyy-MM-dd')
}
function displayDateFormatter(date) {
  return formatDate(date, 'MMM dd')
}

export function SelectPeriod({ onChange }) {
  const [checkedYes, setCheckedYes] = useState(false)
  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)

  const isInvalid = checkedYes && !(startDate || endDate)

  const handleYesCheckbox = useCallback(() => {
    setCheckedYes(!checkedYes)
  }, [checkedYes])

  const handleDatesChange = useCallback((newStartDate, newEndDate) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }, [])

  useEffect(() => {
    onChange(
      checkedYes,
      startDate && toDateQueryFormat(startDate),
      endDate && toDateQueryFormat(endDate)
    )
  }, [onChange, checkedYes, startDate, endDate])

  return (
    <Container>
      <CheckboxWrapper>
        <Checkbox checked={checkedYes} onChange={handleYesCheckbox} data-testid="yes-checkbox" />
        <span>Yes</span>
      </CheckboxWrapper>
      <StyledDateInput
        startDate={startDate}
        endDate={endDate}
        minDate="today"
        stateDateFormatter={stateDateFormatter}
        displayDateFormatter={displayDateFormatter}
        onDatesChange={handleDatesChange}
        range
        invalid={isInvalid}
        disabled={!checkedYes}
      />
    </Container>
  )
}

SelectPeriod.propTypes = {
  onChange: PropTypes.func.isRequired,
}
