import { type TruckType } from 'core/domain/Truck'
import { type Weekday } from 'core/domain/Weekday'

import { type Channel } from '../domain/Channel'
import { type PreferredLane } from '../domain/PreferredLane'

type RawPlace = {
  description: string
  latitude: number
  longitude: number
  radius: number
}

export type RawPreferredLane = {
  channel: Channel
  destination?: RawPlace
  equipment_types: TruckType[]
  expires_at: string | null
  origin?: RawPlace
  rate: number | null
  starts_at: string
  suggested_rate?: number
  uuid: string
  weekdays: Array<Weekday> | null
}

export function mapApiToPreferredLane(rawPreferredLane: RawPreferredLane): PreferredLane {
  if (!rawPreferredLane) {
    throw new TypeError('mapper:: missing preferred lane object')
  }

  return {
    channel: rawPreferredLane.channel,
    destination: rawPreferredLane.destination,
    equipmentTypes: rawPreferredLane.equipment_types,
    expiresAt: rawPreferredLane.expires_at || undefined,
    origin: rawPreferredLane.origin,
    rate: rawPreferredLane.rate ?? undefined,
    startsAt: rawPreferredLane.starts_at,
    suggestedRate: rawPreferredLane.suggested_rate,
    uuid: rawPreferredLane.uuid,
    weekdays: rawPreferredLane.weekdays ?? undefined,
  }
}
