import axios from 'axios'

import http from 'legacy/utils/http'
import requests, { getHeadersDocs } from 'legacy/utils/requests'

import { getClientToken } from './auth'

export function getAccountV2() {
  return axios.get('/api/v2/account', http.getHeadersV2())
}

export async function resetAccountPassword(data) {
  const requestConfig = http.getPublicHeadersV2()
  requestConfig.headers.Authorization = await getClientToken()
  return axios.post(`/api/v2/account/password/reset`, JSON.stringify(data), requestConfig)
}

export function resendEmail(data) {
  return axios.post(
    `/api/v2/account/send_activation_email`,
    JSON.stringify(data),
    http.getPublicHeadersV2()
  )
}

export function updateAccount(data) {
  return requests.put('/api/v2/account/', JSON.stringify(data))
}

export function updateAccountCarrier(data) {
  return requests.patch(`/api/v2/carrier`, data)
}

export function uploadAvatar(data) {
  return getHeadersDocs().put('/api/v2/account/avatar', data)
}

export function setNewPasswordAccount(data) {
  return axios.post(
    `/api/v2/account/password/reset/confirm`,
    JSON.stringify(data),
    http.getPublicHeadersV2()
  )
}

export function verifyAccount(data) {
  return axios.post('/api/v2/account/verify', JSON.stringify(data), http.getPublicHeadersV2())
}
