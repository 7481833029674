import classnames from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { IconCheck } from '@loadsmart/icons'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { screen } from 'legacy/styles/screen'
import uuid from 'legacy/utils/uuid'

import statesMapping from './statesMapping'

const CellWrapper = styled.td`
  position: relative;

  svg {
    color: ${getToken('color-neutral-white')};
  }
`

const StyledIconCheck = styled(IconCheck)`
  left: 4px;
  position: absolute;
  top: 12px;

  ${screen.md} {
    top: 3px;
  }
`

const State = styled.span`
  background-color: ${getToken('color-neutral-lightest')};
  color: ${getToken('color-neutral-dark')};
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: 700;
  line-height: 2.5em;
  max-height: 2.5em;
  margin: 0 0 1px 0;
  overflow: hidden;
  outline: none;
  padding: 1px 0 3px 25px;
  -webkit-font-smoothing: antialiased;

  ${screen.md} {
    background: transparent;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3em;
    margin: 0 5px 1px 0;
    max-height: 2.5em;
    padding: 3px 5px 3px 22px;
  }

  &.hovered {
    background-color: ${getToken('color-neutral-light')};
  }

  &.selected {
    background-color: ${getToken('color-primary')};
    color: ${getToken('color-neutral-white')};
  }
`

const tableRows = [
  ['AK', 'AL', 'IA', 'CT'],
  ['AZ', 'AR', 'IL', 'DE'],
  ['CA', 'FL', 'IN', 'MA'],
  ['CO', 'GA', 'KS', 'MD'],
  ['HI', 'LA', 'KY', 'ME'],
  ['ID', 'MS', 'MI', 'NH'],
  ['MT', 'NC', 'MN', 'NJ'],
  ['NV', 'OK', 'MO', 'NY'],
  ['NM', 'SC', 'ND', 'PA'],
  ['OR', 'TN', 'NE', 'RI'],
  ['UT', 'TX', 'OH', 'VA'],
  ['WA', null, 'SD', 'VT'],
  ['WY', null, 'WI', 'WV'],
  [null, null, null, 'DC'],
]

function getStateObject(abbv) {
  return abbv ? statesMapping[abbv.toLowerCase()] : null
}

const statesAttachedRows = tableRows.map(row => row.map(getStateObject))

function Cell({ state, states, handleClick }) {
  return (
    <CellWrapper>
      {state ? (
        <>
          <State
            id={state.abbv.toLowerCase()}
            className={classnames('state-line', 'ls-u-Truncate', {
              selected: states[state.abbv.toLowerCase()].selected,
            })}
            onClick={handleClick}
            role="button"
            tabIndex={0}
          >
            {state.name}
          </State>
          <StyledIconCheck width={16} height={16} />{' '}
        </>
      ) : (
        <span />
      )}
    </CellWrapper>
  )
}

Cell.propTypes = {
  state: PropTypes.shape({}),
  states: PropTypes.objectOf(
    PropTypes.shape({
      abbv: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  handleClick: PropTypes.func,
}

Cell.defaultProps = {
  state: null,
  states: {},
  handleClick: () => null,
}

function TableBody({ states, handleClick }) {
  return (
    <>
      {statesAttachedRows.map(stateRow => (
        <tr key={uuid.create()}>
          {stateRow.map(stateCell => (
            <Cell key={uuid.create()} state={stateCell} states={states} handleClick={handleClick} />
          ))}
        </tr>
      ))}
    </>
  )
}

TableBody.propTypes = {
  states: PropTypes.objectOf(
    PropTypes.shape({
      abbv: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  handleClick: PropTypes.func,
}

export default TableBody
