import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import { ALL_EQUIPMENT_TYPES, ALL_MODES, EQUIPMENT_SUBTYPES } from 'legacy/constants/equipmentTypes'
import { screen } from 'legacy/styles/screen'
import { getIconFromEquipmentTypeAndMode } from 'legacy/utils/getIconFromEquipmentTypeAndMode'

function presentLabel(item) {
  return item.label
}

function presentAbbr(item) {
  return item.value
}

function presentEquipmentTypeAndMode(equipmentType, mode) {
  const equipmentTypeLabel = presentLabel(equipmentType)
  const modeLabel = presentAbbr(mode)

  if (modeLabel && equipmentTypeLabel) {
    return `${equipmentTypeLabel} - ${modeLabel}`
  }

  return null
}

function getEquipmentLabel({ equipment, mode, displayMode, fallback }) {
  const hasMode = Boolean(mode)
  let result
  if (hasMode && displayMode) {
    result = presentEquipmentTypeAndMode(equipment, mode)
  } else {
    result = presentLabel(equipment)
  }

  return result ?? fallback
}

const Wrapper = styled.section`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;

  > :not(:last-child) {
    margin-right: 6px;
  }

  ${props =>
    !props.forceMobile &&
    `
        ${screen.lg} {
            min-width: auto;
            margin-right: 5px;
        }
    `};
`

const EquipmentIcon = styled.span`
  display: flex;
  align-items: center;

  svg {
    width: 22px;
    height: 22px;
  }
`

const EquipmentLabel = styled.span`
  display: inline-flex;

  font-weight: bold;
  font-size: 14px;
`

const EquipmentSubtypesContainer = styled.div`
  display: inline-flex;

  > :not(:last-child) {
    margin-right: 6px;
  }
`

const EquipmentSubtype = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  height: 18px;
  padding: 0 4px;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;

  background-color: ${getToken('color-neutral-lighter')};
  border-radius: 2px;
`

const Temperature = styled.span`
  flex-shrink: 0;
  height: 18px;
  padding: 0 4px;

  font-size: 12px;

  background-color: ${getToken('color-neutral-lighter')};
  border-radius: 4px;
`

/**
 *
 * @param {Object} props
 * @param {boolean} props.showLabel
 * @param {boolean} props.showOnlyLabel
 * @param {boolean} props.showMode
 * @returns
 */
export function Equipment({
  equipmentType,
  equipmentSubtypes,
  temperature,
  showLabel,
  showOnlyLabel,
  showMode,
  mode: propsMode,
  ...rest
}) {
  const equipment = ALL_EQUIPMENT_TYPES[equipmentType]
  const mode = ALL_MODES[propsMode]
  const Icon = getIconFromEquipmentTypeAndMode({
    mode: mode?.value,
    equipmentType: equipment.value,
  })

  const fallbackIcon = ALL_EQUIPMENT_TYPES['DRV'].icon

  const equipmentLabel = getEquipmentLabel({
    equipment,
    mode,
    fallback: equipmentType,
    displayMode: showMode,
  })

  const hasTemperature = Boolean(temperature !== null && temperature >= 0)

  const showSubtypes = showLabel && equipmentSubtypes?.length > 0

  const displayLabel = showLabel || showOnlyLabel
  const displayIcon = !showOnlyLabel
  return (
    <Wrapper {...rest}>
      {displayIcon && <EquipmentIcon>{Icon ? <Icon /> : fallbackIcon}</EquipmentIcon>}
      {displayIcon && hasTemperature && <Temperature>{temperature}°</Temperature>}
      {displayLabel && <EquipmentLabel>{equipmentLabel}</EquipmentLabel>}
      {showSubtypes && (
        <EquipmentSubtypesContainer>
          {equipmentSubtypes.map(subtype => (
            <EquipmentSubtype key={subtype}>
              {EQUIPMENT_SUBTYPES[subtype] || subtype.replace('_', ' ')}
            </EquipmentSubtype>
          ))}
        </EquipmentSubtypesContainer>
      )}
    </Wrapper>
  )
}

Equipment.propTypes = {
  equipmentType: PropTypes.string.isRequired,
  equipmentSubtypes: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(EQUIPMENT_SUBTYPES))),
  temperature: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showLabel: PropTypes.bool,
  showOnlyLabel: PropTypes.bool,
  mode: PropTypes.string,
}

Equipment.defaultProps = {
  equipmentSubtypes: null,
  temperature: null,
  showLabel: false,
  showOnlyLabel: false,
  showMode: false,
}

export default Equipment
