import PropTypes from 'prop-types'

const place = PropTypes.shape({
  address: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
})

export const preferredLaneType = PropTypes.shape({
  pickup: place,
  delivery: place,
  equipmentType: PropTypes.string,
})

export const laneType = PropTypes.shape({
  pickup: place,
  delivery: place,
})
