import PropTypes from 'prop-types'
import {
  forwardRef,
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react'
import MaskedInput from 'react-text-mask'
import styled, { css } from 'styled-components/macro'

import { IconClose } from '@loadsmart/icons'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'

import useWindowWidth from 'core/ui/hooks/useWindowWidth'

import Checkbox from 'legacy/components/Checkbox/Checkbox'
import LoadingV2 from 'legacy/components/LoadingV2/LoadingV2'
import { screen } from 'legacy/styles/screen'

const INPUT_HEIGHT = 36
const SUGGESTIONS_HEIGHT = 213

const ICON_HORIZONTAL_OFFSET = 8
const INPUT_HORIZONTAL_PADDING = 32 + ICON_HORIZONTAL_OFFSET

const LAYERS = {
  clearButton: 1,
  floatingLabel: 1,
  icon: 2,
  loadingIcon: 2,
  inputFocus: 2,
  suggestionsList: 3,
}

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  ${screen.md} {
    display: inline-flex;
  }
`

const InputInnerContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
  width: 100%;
`

const InputIconContainer = styled.div`
  position: absolute;
  z-index: ${LAYERS.icon};
  color: ${getToken('color-neutral-dark')};
  left: ${props => (props.iconAlignment === 'left' ? `${ICON_HORIZONTAL_OFFSET}px` : 'auto')};
  right: ${props => (props.iconAlignment === 'right' ? `0` : 'auto')};
  transition: color 120ms linear;
  width: 24px;
  max-height: 100%;
  top: 0;
  bottom: 0;
  display: inline-flex;
  justify-content: left;
  align-items: center;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};

  ${screen.md} {
    z-index: auto;
  }
`

const InputLoadingContainer = styled.div`
  z-index: ${LAYERS.loadingIcon};
  position: absolute;
  color: ${getToken('color-neutral-dark')};
  left: ${({ iconAlignment }) =>
    iconAlignment === 'right' ? `${ICON_HORIZONTAL_OFFSET}px` : 'auto'};
  right: ${({ iconAlignment }) =>
    iconAlignment === 'left' ? `${ICON_HORIZONTAL_OFFSET}px` : 'auto'};
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
  margin-top: -1px;
  transition: opacity 100ms linear;

  ${screen.md} {
    z-index: auto;
  }
`

const InputClearContainer = styled.div`
  z-index: ${LAYERS.clearButton};
  position: absolute;
  color: ${getToken('color-neutral-dark')};
  left: ${props => (props.iconAlignment === 'right' ? `${ICON_HORIZONTAL_OFFSET / 2}px` : 'auto')};
  right: ${props => (props.iconAlignment === 'left' ? `${ICON_HORIZONTAL_OFFSET / 2}px` : 'auto')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  margin-top: -1px;
  transition: opacity 100ms ease-in-out;
  width: ${(INPUT_HEIGHT / 3) * 2}px;
  height: ${INPUT_HEIGHT - 2}px;
  cursor: ${({ isVisible }) => (isVisible ? 'pointer' : 'auto')};
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  color: ${getToken('color-neutral-dark')};

  svg {
    color: ${getToken('color-neutral-dark')};
  }

  &:hover {
    svg {
      color: ${getToken('color-neutral-darkest')};
    }
  }
`

const InputClearButton = styled.div`
  outline: 0;
  background-color: transparent;
  width: ${(INPUT_HEIGHT / 3) * 2}px;
  height: ${INPUT_HEIGHT - 2}px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const ClearIcon = styled(IconClose)`
  width: 11px;
  height: 11px;
  margin-bottom: -2px;
`

const activeFloatingLabelStyle = css`
  transform: translateY(-9px);
  font-size: 8px;
  text-transform: uppercase;
  color: ${getToken('color-neutral-darkest')};
`

const FloatingLabelContainer = styled.div`
  position: absolute;
  z-index: ${LAYERS.floatingLabel};
  left: 13px;
  font-size: 14px;
  color: ${getToken('color-neutral')};
  transition: all 100ms ease-in-out;
  pointer-events: none;

  ${({ isActive }) => (isActive ? activeFloatingLabelStyle : '')};
`

const invalidInputStyle = css`
  color: ${getToken('color-danger')};
  border-color: ${getToken('color-danger')};

  ${InputIconContainer} {
    color: ${getToken('color-danger')};
  }

  &:focus {
    color: ${getToken('color-danger')};
    border-color: ${getToken('color-danger')};

    & ~ ${InputIconContainer} {
      color: ${getToken('color-danger')};
    }
  }
`

const disabledStyle = css`
  opacity: 0.5;
`

const noIconInputStyle = css`
  padding: 0 12px;
`

const withSuggestionsInputStyle = css`
  border-bottom-color: ${getToken('color-neutral-white')} !important;
  border-radius: 4px 4px 0 0;
`

const inputBaseStyle = css`
  position: relative;
  display: block;
  color: ${getToken('color-neutral-darkest')};
  background-color: ${getToken('color-neutral-white')};
  border: 1px solid ${getToken('color-neutral-dark')};
  outline: 0;
  height: ${INPUT_HEIGHT}px;
  width: 100%;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 4px;
  opacity: 1;
  box-shadow: 0 !important;
  transition: border-color 100ms linear, color 120ms linear, opacity 120ms linear;

  &::placeholder {
    color: ${getToken('color-neutral')};
  }

  &:focus {
    z-index: ${LAYERS.inputFocus};
    color: ${getToken('color-neutral-darkest')};
    border-color: ${getToken('color-primary')};

    & ~ ${InputIconContainer} {
      color: ${getToken('color-neutral-darkest')};
    }

    & ~ ${FloatingLabelContainer} {
      ${activeFloatingLabelStyle};
    }
  }

  &:invalid {
    ${invalidInputStyle};
  }

  &:disabled {
    ${disabledStyle};
  }

  ${props => (props.invalid ? invalidInputStyle : '')};

  ${screen.md} {
    &:focus {
      z-index: auto;
    }
  }
`

const activeFloatingLabelInputStyle = css`
  padding-top: 10px;
`

const withFloatingLabelInputStyle = css`
  &:focus {
    ${activeFloatingLabelInputStyle};
  }

  ${({ isFloatingLabelActive }) => (isFloatingLabelActive ? activeFloatingLabelInputStyle : '')};
`

const withClearButtonInputStyle = css`
  padding-right: 30px;
`

const inputCommonStyle = css`
  ${inputBaseStyle};

  padding-left: ${({ iconAlignment }) =>
    iconAlignment === 'left' ? `${INPUT_HORIZONTAL_PADDING}px` : '10px'};
  padding-right: ${({ iconAlignment }) => (iconAlignment === 'right' ? '30px' : '10px')};
  cursor: ${props => (props.selectOnly ? 'pointer' : 'auto')};

  ${props => (props.hasIcon ? '' : noIconInputStyle)};
  ${props => (props.hasSuggestions && props.isSuggestionsOpen ? withSuggestionsInputStyle : '')};
  ${props => (props.hasFloatingLabel ? withFloatingLabelInputStyle : '')};
  ${props => (props.hasClearButton ? withClearButtonInputStyle : '')};
`

const StyledInput = styled.input`
  ${inputCommonStyle};
`

const StyledMaskedInput = styled(
  forwardRef(
    (
      {
        invalid,
        selectOnly,
        isSuggestionsOpen,
        hasIcon,
        hasSuggestions,
        iconAlignment,
        hasClearButton,
        hasFloatingLabel,
        isFloatingLabelActive,
        isFilled,
        ...passThrough
      },
      ref
    ) => <MaskedInput {...passThrough} ref={ref} />
  )
)`
  ${inputCommonStyle};
`

const InputSuggestionsContainer = styled.div`
  position: absolute;
  top: ${INPUT_HEIGHT}px;
  width: 100%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  z-index: ${LAYERS.suggestionsList};
  left: 0;
  max-height: ${props => (props.isSuggestionsOpen ? `${SUGGESTIONS_HEIGHT}px` : 0)};
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${getToken('color-neutral-white')};
  border-color: ${getToken('color-primary')};
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 4px 4px;
  opacity: ${props => (props.isSuggestionsOpen ? 1 : 0)};
  transition: opacity 120ms linear, max-height 120ms linear;

  ${screen.md} {
    box-shadow: none;
  }
`

const InputSuggestionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  font-size: 13px;
  color: ${getToken('color-neutral-darkest')};
  cursor: ${props => (props.isSuggestionsOpen && !props.isLightlySelected ? 'pointer' : 'auto')};
  transition: background-color 120ms linear;

  background-color: ${props =>
    props.isSelected
      ? getToken('color-success-light')
      : props.isLightlySelected
      ? getToken('color-neutral-lightest')
      : getToken('color-neutral-white')};

  &:hover {
    background-color: ${props =>
      props.isLightlySelected ? getToken('color-neutral-light') : getToken('color-success')};
  }
`

const StyledCheckbox = styled(Checkbox)`
  margin-right: 13px;
`

export function Input({
  icon,
  iconAlignment,
  isLoading,
  suggestions,
  selectedSuggestion,
  initialSuggestionIndex,
  mask,
  onSuggestionSelect,
  onChange,
  onBlur,
  onFocus,
  onClearButtonClick,
  getInputRef,
  selectOnly,
  defaultValue,
  disabled,
  blockSuggestions,
  useFloatingLabel,
  placeholder,
  forcedPlaceholder,
  isMultipleSelect,
  selectedOptions,
  ...passThrough
}) {
  const givenValue = passThrough.value || defaultValue

  const [isSuggestionsOpen, setSuggestionsOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(initialSuggestionIndex)
  const [hasFocusedOnce, setHasFocusedOnce] = useState(selectOnly)
  const [isFocused, setFocused] = useState(selectOnly)
  const [isFilled, setFilled] = useState(givenValue ? true : false)

  const inputRef = useRef()
  const hasTemporarySelection = useMemo(() => selectedIndex >= 0, [selectedIndex])
  const IconComponent = useMemo(() => (icon ? icon : null), [icon])
  const hasIcon = useMemo(() => IconComponent !== null, [IconComponent])
  const hasSuggestions = useMemo(() => suggestions.length > 0, [suggestions])

  const useClearButton = useMemo(
    () => (onClearButtonClick && typeof onClearButtonClick === 'function' ? true : false),
    [onClearButtonClick]
  )

  const hasForcedPlaceholder = useMemo(
    () => (useFloatingLabel && forcedPlaceholder ? true : false),
    [forcedPlaceholder, useFloatingLabel]
  )

  const isFloatingLabelActive = useMemo(
    () => isFilled || isFocused || hasForcedPlaceholder,
    [hasForcedPlaceholder, isFilled, isFocused]
  )

  const windowWidth = useWindowWidth()
  const isDesktop = windowWidth >= 1024

  const Primitive = useMemo(
    () => (mask && mask.length > 0 ? StyledMaskedInput : StyledInput),
    [mask]
  )

  const closeSuggestions = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.blur()
    }

    return setSuggestionsOpen(false)
  }, [inputRef])

  const handleChange = useCallback(
    event => {
      if (selectOnly) {
        inputRef.current.value = ''
        return false
      }

      setFilled(event.target.value ? true : false)
      return onChange(event)
    },
    [selectOnly, onChange]
  )

  const handleFocus = useCallback(
    event => {
      setHasFocusedOnce(true)
      setFocused(true)
      setSuggestionsOpen(blockSuggestions ? false : hasSuggestions)

      return onFocus(event)
    },
    [blockSuggestions, hasSuggestions, onFocus]
  )

  const handleBlur = useCallback(
    event => {
      setSuggestionsOpen(false)
      setFocused(false)

      return onBlur(event)
    },
    [onBlur]
  )

  const handleInputClick = useCallback(
    event => {
      if (isSuggestionsOpen) {
        if (!isDesktop) {
          event.preventDefault()
        }

        if (selectOnly) {
          closeSuggestions()
        }
      }
    },
    [closeSuggestions, isDesktop, isSuggestionsOpen, selectOnly]
  )

  const handleSuggestionClick = useCallback(
    event => {
      event.preventDefault()

      if (!isSuggestionsOpen) {
        return false
      }

      onSuggestionSelect(event.target.id, event)

      if (!isMultipleSelect) {
        closeSuggestions()
      }
    },
    [closeSuggestions, isMultipleSelect, isSuggestionsOpen, onSuggestionSelect]
  )

  const handleIconClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleClearButtonClick = useCallback(
    event => {
      if (!useClearButton || !isFilled) {
        return false
      }

      return onClearButtonClick(event)
    },
    [isFilled, onClearButtonClick, useClearButton]
  )

  const suggestionList = useMemo(() => {
    return suggestions.map((someSuggestion, index) => (
      <InputSuggestionContainer
        id={someSuggestion.value}
        key={someSuggestion.value}
        isSelected={hasTemporarySelection ? selectedIndex === index : initialSuggestionIndex}
        isLightlySelected={
          isMultipleSelect
            ? selectedOptions.includes(someSuggestion.value)
            : selectedSuggestion && selectedSuggestion.value === someSuggestion.value
        }
        isSuggestionsOpen={isSuggestionsOpen}
        onMouseDown={handleSuggestionClick}
        data-testid="input-suggestion"
      >
        {isMultipleSelect && (
          <StyledCheckbox
            checked={selectedOptions.includes(someSuggestion.value)}
            onChange={evt => {
              evt.preventDefault()
            }}
            id={someSuggestion.value}
          />
        )}
        {someSuggestion.label}
      </InputSuggestionContainer>
    ))
  }, [
    suggestions,
    hasTemporarySelection,
    selectedIndex,
    initialSuggestionIndex,
    selectedSuggestion,
    isSuggestionsOpen,
    handleSuggestionClick,
    selectedOptions,
    isMultipleSelect,
  ])

  const mayMoveUp = useMemo(() => selectedIndex > 0, [selectedIndex])
  const mayMoveDown = useMemo(
    () => selectedIndex < suggestions.length - 1,
    [selectedIndex, suggestions]
  )
  const mayEnterSelection = useMemo(
    () => hasSuggestions && selectedIndex >= 0,
    [hasSuggestions, selectedIndex]
  )

  const moveSelectionUp = useCallback(() => {
    if (mayMoveUp) {
      return setSelectedIndex(selectedIndex - 1)
    }
  }, [mayMoveUp, selectedIndex])

  const moveSelectionDown = useCallback(() => {
    if (mayMoveDown) {
      return setSelectedIndex(selectedIndex + 1)
    }
  }, [mayMoveDown, selectedIndex])

  const enterSelection = useCallback(() => {
    if (mayEnterSelection) {
      onSuggestionSelect(suggestions[selectedIndex].value, {})

      if (!isMultipleSelect) {
        closeSuggestions()
      }
    }
  }, [
    mayEnterSelection,
    onSuggestionSelect,
    suggestions,
    selectedIndex,
    isMultipleSelect,
    closeSuggestions,
  ])

  const keyHandlers = useMemo(
    () => ({
      27: closeSuggestions,
      38: moveSelectionUp,
      40: moveSelectionDown,
      13: enterSelection,
    }),
    [closeSuggestions, moveSelectionUp, moveSelectionDown, enterSelection]
  )

  const handleKey = useCallback(
    event => keyHandlers[event.keyCode] && keyHandlers[event.keyCode].call(),
    [keyHandlers]
  )

  const bindKeys = useCallback(() => window.addEventListener('keydown', handleKey), [handleKey])
  const unbindKeys = useCallback(
    () => window.removeEventListener('keydown', handleKey),
    [handleKey]
  )

  useEffect(() => getInputRef(inputRef), [getInputRef, inputRef])

  useEffect(
    () => setSuggestionsOpen(selectOnly || !hasFocusedOnce ? false : hasSuggestions),
    [hasFocusedOnce, hasSuggestions, selectOnly]
  )

  useLayoutEffect(() => {
    function cleanup() {
      window.document.body.style.overflowY = 'auto'
    }

    if (selectOnly || isDesktop) {
      return cleanup
    }

    window.document.body.style.overflowY = isSuggestionsOpen ? 'hidden' : 'auto'
    return cleanup
  }, [isDesktop, isSuggestionsOpen, selectOnly, windowWidth])

  useEffect(() => {
    setFilled(givenValue ? true : false)
  }, [givenValue])

  useEffect(() => {
    if (isSuggestionsOpen) {
      bindKeys()
    } else {
      unbindKeys()
      setSelectedIndex(initialSuggestionIndex)
    }

    return unbindKeys
  }, [isSuggestionsOpen, initialSuggestionIndex, bindKeys, unbindKeys])

  return (
    <InputContainer>
      <InputInnerContainer>
        <Primitive
          {...passThrough}
          placeholder={
            useFloatingLabel ? (isFloatingLabelActive ? forcedPlaceholder : '') : placeholder
          }
          hasFloatingLabel={useFloatingLabel}
          hasClearButton={useClearButton}
          isFloatingLabelActive={isFloatingLabelActive}
          isFilled={isFilled}
          mask={mask}
          defaultValue={selectOnly ? undefined : defaultValue}
          disabled={disabled}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseDown={handleInputClick}
          hasIcon={hasIcon}
          iconAlignment={iconAlignment}
          hasSuggestions={hasSuggestions}
          isSuggestionsOpen={isSuggestionsOpen}
          selectOnly={selectOnly}
          readOnly={selectOnly}
          data-is-suggestions-open={isSuggestionsOpen}
          ref={inputRef}
        />

        {IconComponent && (
          <InputIconContainer
            iconAlignment={iconAlignment}
            onClick={handleIconClick}
            disabled={disabled}
            data-testid="input-icon"
          >
            <IconComponent height={24} />
          </InputIconContainer>
        )}

        {useFloatingLabel && (
          <FloatingLabelContainer isActive={isFloatingLabelActive}>
            {placeholder}
          </FloatingLabelContainer>
        )}

        {useClearButton && (
          <InputClearContainer
            iconAlignment={iconAlignment}
            isVisible={isFilled}
            onClick={handleClearButtonClick}
            data-testid="input-clear"
          >
            <InputClearButton>
              <ClearIcon />
            </InputClearButton>
          </InputClearContainer>
        )}

        <InputLoadingContainer
          iconAlignment={iconAlignment}
          isLoading={isLoading}
          data-testid="input-loading"
        >
          <LoadingV2 width={10} height={10} />
        </InputLoadingContainer>
      </InputInnerContainer>

      <InputSuggestionsContainer isSuggestionsOpen={isSuggestionsOpen}>
        {suggestionList}
      </InputSuggestionsContainer>
    </InputContainer>
  )
}

const SuggestionItemPropShape = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.any,
})

Input.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  iconAlignment: PropTypes.oneOf(['left', 'right']),
  invalid: PropTypes.bool,
  selectOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  blockSuggestions: PropTypes.bool,
  suggestions: PropTypes.arrayOf(SuggestionItemPropShape),
  selectedSuggestion: SuggestionItemPropShape,
  initialSuggestionIndex: PropTypes.number,
  mask: PropTypes.arrayOf(PropTypes.any),
  onSuggestionSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onClearButtonClick: PropTypes.func,
  getInputRef: PropTypes.func,
  useFloatingLabel: PropTypes.bool,
  forcedPlaceholder: PropTypes.node,
  isMultipleSelect: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
}

Input.defaultProps = {
  icon: undefined,
  iconAlignment: 'left',
  invalid: false,
  selectOnly: false,
  disabled: false,
  isLoading: false,
  blockSuggestions: false,
  suggestions: [],
  selectedSuggestion: {},
  initialSuggestionIndex: 0,
  mask: null,
  onSuggestionSelect: Function.prototype,
  onBlur: Function.prototype,
  onFocus: Function.prototype,
  onChange: Function.prototype,
  getInputRef: Function.prototype,
  onClearButtonClick: null,
  useFloatingLabel: false,
  forcedPlaceholder: '',
  isMultipleSelect: false,
  selectedOptions: [],
}

export {
  inputBaseStyle,
  InputContainer,
  InputInnerContainer,
  FloatingLabelContainer,
  InputClearContainer,
  INPUT_HEIGHT,
}

export default Input
