import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { Layout, Text, Dialog } from '@loadsmart/miranda-react'

import { analytics } from 'legacy/utils/analytics'

import { useCarrierContext } from 'auth/ui/context/CarrierContext'
import useRegionsOfOperation from 'carrier/ui/hooks/useRegionsOfOperation'
import RegionsOfOperation from 'carrier/ui/RegionsOfOperation'
import { updateCarrier } from 'carrier/use-cases/updateCarrier'

import { OnboardingStep } from '../domain/Onboarding'

const analyticsEvents = {
  onView: 'Setup / Regions Of Operation / View',
  onAddSuccess: 'Setup / Regions Of Operation / Add',
  onAddError: 'Setup / Regions Of Operation / Add / Error',
}

interface RegionsOfOperationStepProps {
  handleStep: (stepName: OnboardingStep, nextStepName?: OnboardingStep) => void
  prevStep?: OnboardingStep
  nextStep?: OnboardingStep
}

export default function RegionsOfOperationStep({
  handleStep,
  nextStep,
}: RegionsOfOperationStepProps) {
  const { states, regions, toggleStateSelection, toggleRegionSelection } = useRegionsOfOperation()
  const { setCarrier } = useCarrierContext()

  useEffect(() => {
    analytics.track(analyticsEvents.onView)
  }, [])

  async function handleUpdateRegions() {
    try {
      const carrier = await updateCarrier({ regions })
      setCarrier(carrier)
      analytics.track(analyticsEvents.onAddSuccess)
      toast.success('Saved')
    } catch (error) {
      analytics.track(analyticsEvents.onAddError)
      toast.error('Could not save')
    }
  }

  return (
    <>
      <Dialog.Body>
        <Layout.Stack gap="spacing-6">
          <Layout.Stack gap="spacing-4">
            <Text variant="heading-md-bold">Set up your regions of operations</Text>
            <Text>
              Tell us your regions of operations so we can focus on the best matches for you!
            </Text>
            <RegionsOfOperation
              selectedStates={states}
              onStateSelection={toggleStateSelection}
              onRegionSelection={toggleRegionSelection}
            />
          </Layout.Stack>
        </Layout.Stack>
      </Dialog.Body>
      <Dialog.Footer>
        <Layout.Group justify="flex-end">
          <Dialog.ActionPrimary
            onClick={() => {
              handleStep(OnboardingStep.RegionsOfOperation, nextStep)
              handleUpdateRegions()
            }}
            disabled={regions.length === 0}
          >
            {nextStep ? 'Next' : 'Save'}
          </Dialog.ActionPrimary>
        </Layout.Group>
      </Dialog.Footer>
    </>
  )
}
