import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getToken() {
  let token = false
  let authorization = ''

  if (typeof window !== 'undefined') {
    token = window.localStorage.getItem('loadSmart.user-token')
  }

  if (!token) {
    authorization = `Basic YWJjMTIzOnNzaC1zZWNyZXQ=`
  } else {
    authorization = `Bearer ${token}`
  }

  return authorization
}

export function getHeaders() {
  return {
    baseURL: API_URL,
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json',
    },
  }
}

export function getPublicHeaders() {
  let headers = {
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return axios.create(headers)
}

export function getHeadersDocs() {
  let headers = {
    baseURL: API_URL,
    headers: {
      Authorization: getToken(),
      'Content-Type': 'multipart/form-data',
    },
  }
  return axios.create(headers)
}

export const requests = axios.create(getHeaders())

requests?.interceptors.request.use(config => {
  return {
    ...config,
    headers: {
      ...config.headers,
      ...getHeaders().headers,
    },
  }
})

export default requests
