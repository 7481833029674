import http from 'legacy/utils/http'
import requests from 'legacy/utils/requests'

export function addPreferredLane(data) {
  return requests.post(
    `/api/v2/loadboard/preferred-lanes`,
    JSON.stringify({ ...data, channel: 'carrier' }),
    http.getHeaders()
  )
}

export function updatePreferredLaneRate(laneId, rate) {
  return requests.patch(`/api/v2/loadboard/preferred-lanes/${laneId}`, JSON.stringify({ rate }))
}

export function deletePreferredLaneRate(laneId) {
  return requests.patch(
    `/api/v2/loadboard/preferred-lanes/${laneId}`,
    JSON.stringify({ rate: null })
  )
}

/**
 * Fetch the suggested rate for a preferred lane from our pricing algorithm
 * @param {object} data
 * @param {object} data.origin
 * @param {string} data.origin.description
 * @param {number} data.origin.latitude
 * @param {number} data.origin.longitude
 * @param {number} data.origin.radius
 * @param {object} data.delivery
 * @param {string} data.delivery.description
 * @param {number} data.delivery.latitude
 * @param {number} data.delivery.longitude
 * @param {number} data.delivery.radius
 * @param {string} data.equipment_type
 * @returns {{ suggested_rate: number }}
 */
export function getLaneSuggestedRate(data) {
  return requests.post(`/api/v2/loadboard/preferred-lanes/suggested-rate`, data)
}
